import { Container } from '@component/Section/Container';
import styled from 'styled-components';

import { Section } from './Section';
import { SkillsInterface } from './Skills.type';

export interface LineInterface {
  lineColor?: string;
}

const LineBase = styled.hr`
  background-color: ${(props: LineInterface) => props.lineColor};
  border: none;
  border-top: '0.9px dashed #CBCBCB';
  height: 1px;
  margin-top: 0;
  width: 100%;
`;

const Line = (props: LineInterface) => {
  return <LineBase {...props} />;
};

const Content = (props: { isTitle?: boolean; text: string }) => {
  return props.isTitle ? (
    <p>
      <strong>{props.text}</strong>
    </p>
  ) : (
    <p>{props.text}</p>
  );
};

export const Skills = (props: SkillsInterface) => (
  <Section title={'Skills & Expertise'}>
    <Container>
      {props.skills.map((item, index) => (
        <div key={index}>
          <div className="my-3">
            <div>
              <Content isTitle text={item.title} />
            </div>
            <ul>
              {item.list.map((skill, i) => (
                <li key={i} v-for="item in items" className="inline-flex first:mx-0 mx-2">
                  <Content text={skill} />
                </li>
              ))}
            </ul>
          </div>
          <Line lineColor="#2CA7F6" />
        </div>
      ))}
    </Container>
  </Section>
);
