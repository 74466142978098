import { CSSProperties } from 'styled-components';

// TODO Change style to strict props
export const Container = (props: {
  children: React.ReactNode;
  style?: CSSProperties;
  className?: string;
}) => {
  return (
    <div className={'mx-3 md:mx-12'} style={props.style}>
      {props.children}
    </div>
  );
};
