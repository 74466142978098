import { Education } from '@component/Section/Education';
import { Experience } from '@component/Section/Experience';
import { Header } from '@component/Section/Header';
import { Skills } from '@component/Section/Skills';
import { portfolioData } from '@constant/data';

const Profile = () => {
  const { educationData, headerData, roadMap, skillData } = portfolioData;

  return (
    <div className="py-6 md:p-12">
      <Header {...headerData} />
      <Experience experiences={roadMap} />
      <Skills skills={skillData} />
      <Education {...educationData} />
    </div>
  );
};

export default Profile;
