import { Icon } from '@component/Icon';
import { Container } from '@component/Section/Container';
import Image from 'next/image';
import styled from 'styled-components';

import { HeaderInterface } from './Header.type';

const BioStyled = styled.div`
  align-self: center;
  text-align: left;
  @media (max-width: 768px) {
    text-align: center;
    margin: 15px auto;
  }
`;

export const Header = (props: HeaderInterface) => (
  <Container>
    <div className="flex flex-col md:flex-row">
      <div style={{ alignSelf: 'center', textAlign: 'center' }}>
        <div className="relative w-32 h-32">
          <Image
            src={props.avatarUri}
            layout="fill"
            objectFit="contain"
            className="rounded-full border border-gray-100 shadow-md"
          />
        </div>
      </div>
      <BioStyled className="flex flex-1 flex-col ml-4 items-center md:items-start">
        <h4>
          <strong>{props.name}</strong>
        </h4>
        <h6 style={{ color: '#49545A' }}>{props.title}</h6>
        <p className="m-0" style={{ color: '#49545A' }}>
          {new Date().getFullYear() - 2016} Years Experience
        </p>
        <span
          style={{
            color: '#49545A',
            display: 'flex',
            alignContent: 'center',
            alignItems: 'center'
          }}
        >
          <div className="flex mr-2">
            <Icon name="map-pin" color="#49545A" variant="small" />
          </div>
          <p style={{ margin: 0 }}>{props.domicile}</p>
        </span>
      </BioStyled>
      <div className="flex flex-1 justify-center items-center md:justify-end">
        {props.socialMedia.map((item) => (
          <a
            key={item.iconName}
            target="_blank"
            rel="noopener noreferrer"
            href={item.url}
            className="mr-3"
          >
            <Icon name={item.iconName} />
          </a>
        ))}
      </div>
    </div>
  </Container>
);
