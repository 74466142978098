import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel
} from '@chakra-ui/accordion';
import { Container } from '@component/Section/Container';
import React from 'react';

export const Section = (props: {
  children: (React.ReactElement<unknown, string | React.JSXElementConstructor<unknown>> &
    React.ReactNode) &
    React.ReactNode;
  title: string;
}) => {
  // TODO Refactor state
  const SectionHeader = (props: { title: string }) => {
    return (
      <Container>
        <div className="cursor-pointer flex flex-row">
          <div className="flex flex-1 flex-col">
            <h5>
              <strong>{props.title}</strong>
            </h5>
          </div>
        </div>
      </Container>
    );
  };

  return (
    <Accordion allowMultiple allowToggle className="my-6">
      <AccordionItem border="0">
        <AccordionButton borderBottom="1.5px" borderStyle="dotted" borderBottomColor="gray.300">
          <SectionHeader title={props.title} />
          <div className="flex flex-1 justify-end items-end">
            <AccordionIcon />
          </div>
        </AccordionButton>
        <AccordionPanel pb={4}>{props.children}</AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
