import { Icon } from '@component/Icon';
import { Container } from '@component/Section/Container';
import { dateFormatter } from '@util/dateFormatter';
import styled from 'styled-components';

import { EducationInterface } from './About.type';
import { Section } from './Section';

const EducationStyled = styled.div`
  align-self: 'center';
  h6 {
    margin: 0;
  }
  p {
    margin: 0;
  }
  @media (max-width: 768px) {
    h6 {
      margin: 2px auto;
    }
  }
`;

export const Education = (props: EducationInterface) => (
  <Section title={'About & Education'}>
    <Container>
      <EducationStyled className="flex flex-1 my-3">
        <div className="flex justify-center items-center">
          <Icon name="education" />
        </div>

        <div className="latestEdu ml-3">
          <h6>
            <strong>{props.major}</strong>
          </h6>
          <p style={{ textTransform: 'capitalize' }}>{props.degree}</p>
          <p style={{ textTransform: 'capitalize' }}>{props.university}</p>
          <p>
            {dateFormatter({
              date: props.graduated,
              format: 'yyyy'
            })}
          </p>
        </div>
      </EducationStyled>
    </Container>
  </Section>
);
