import React from 'react';
import {
  BookOpen,
  ChevronDown,
  ChevronUp,
  GitHub,
  IconProps,
  Instagram,
  Link,
  Linkedin,
  Mail,
  MapPin,
  Twitter
} from 'react-feather';

import { IconInterface, IconType, IconVariantType } from './icon.type';

export const Icon = (props: IconInterface) => {
  const sizeMapper: Record<IconVariantType, number> = {
    small: 14,
    medium: 32
  };

  const iconProps: IconProps = {
    size: sizeMapper[props.variant ?? 'medium'],
    color: props.color ?? '#2CA7F6'
  };

  const iconMapper: Record<IconType, React.ReactElement> = {
    'chevron-up': <ChevronUp {...iconProps} />,
    'chevron-down': <ChevronDown {...iconProps} />,
    'map-pin': <MapPin {...iconProps} />,
    linkedin: <Linkedin {...iconProps} />,
    twitter: <Twitter {...iconProps} />,
    instagram: <Instagram {...iconProps} />,
    github: <GitHub {...iconProps} />,
    email: <Mail {...iconProps} />,
    education: <BookOpen {...iconProps} />,
    link: <Link {...iconProps} />
  };
  return iconMapper[props.name];
};
