import * as dateFns from 'date-fns';
import id from 'date-fns/locale/id';

type DayFormatType = 'yyyy' | 'MMM yyyy';

type DateFormatType = DayFormatType;

export const dateFormatter = ({ date, format }: { date: Date; format?: DateFormatType }) => {
  return dateFns.format(date, format || 'dd MMMM yyyy', {
    locale: id
  });
};

export const formatDistanceToNow = (date: Date) =>
  dateFns.formatDistanceToNow(date, {
    locale: id
  });
