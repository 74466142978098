import { Container } from '@component/Section/Container';
import { dateFormatter } from '@util/dateFormatter';
import styled from 'styled-components';

import { ExperienceItemInterface, ExperienceSectionInterface } from './Experience.type';
import { Section } from './Section';

const OrderedListStyled = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const ListItemStyled = styled.li`
  background-color: #e0ebfd;
  list-style-type: none;
  margin: 0 auto;
  padding-top: 25px;
  position: relative;
  width: 2.5px;
  @media (max-width: 768px) {
    margin: 0;
    background-color: #fff;
    width: 100%;
    :nth-child(1) {
      padding-top: 0;
    }
  }
  ::after {
    background: #2ca7f6;
    border-radius: 50%;
    bottom: 35%;
    content: '';
    height: 30px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 30px;
    @media (max-width: 768px) {
      width: 0;
      height: 0;
    }
  }
  div {
    background: #e0ebfd;
    border-radius: 15px;
    bottom: 0;
    padding: 15px;
    position: relative;
    width: 400px;
    @media (max-width: 768px) {
      left: 0;
      width: 100%;
    }
  }
  :nth-child(odd) {
    div {
      left: 45px;
      @media (max-width: 768px) {
        left: 0;
      }
    }
  }

  :nth-child(even) {
    div {
      left: -439px;
      @media (max-width: 768px) {
        left: 0;
      }
    }
  }
`;
const ExperienceItem = ({
  company,
  jobTitle,
  from,
  onClick,
  managedPeople,
  to
}: ExperienceItemInterface) => (
  <ListItemStyled className="cursor-pointer" onClick={onClick}>
    <div>
      <time>
        {`${dateFormatter({
          date: from,
          format: 'MMM yyyy'
        })} - ${to ? dateFormatter({ date: to, format: 'MMM yyyy' }) : 'Present'}`}
      </time>
      <h3>
        <strong>{company}</strong>
      </h3>
      <h6>
        <strong>{jobTitle}</strong>
      </h6>
      {/* TODO: add details */}
      {/* <ul>
        {details?.map((item, index) => (
          <li key={index}>
            <p>{item}</p>
          </li>
        ))}
      </ul> */}
      {managedPeople && <p>Manages {managedPeople} people </p>}
    </div>
  </ListItemStyled>
);

export const Experience = (props: ExperienceSectionInterface) => (
  <Section title={'Work Experience'}>
    <Container>
      <div>
        <div>
          <OrderedListStyled className="m-3 md:m-0">
            {props.experiences.map((item, index) => {
              if (!item.isHideFromPublic) {
                return <ExperienceItem key={index} {...item} />;
              }
            })}
          </OrderedListStyled>
        </div>
      </div>
    </Container>
  </Section>
);
